<template>
  <div class="contentView">
    <div class="centerView searchBox">
      <!-- 多选功能后期接口有了替换 -->
      <!-- <a-select
        v-model:value="bId"
        mode="multiple"
        style="width: 300px"
        placeholder="选择店铺"
        :max-tag-count="2"
      >
        <a-select-option
          :value="item.idCode"
          v-for="(item, index) in storeList"
          :key="index"
          >{{ item.name }}</a-select-option
        >
        <template #maxTagPlaceholder="omittedValues">
          <span style="color: red">+ {{ omittedValues.length }} ...</span>
        </template>
      </a-select> -->
      <a-select v-model:value="bId" style="width: 200px" :placeholder="t('selectShop')" allowClear>
        <a-select-option :value="item.idCode" v-for="(item, index) in storeList" :key="index">{{ item.name }}</a-select-option>
      </a-select>
      <a-month-picker v-model:value="month" :placeholder="t('selectMonth')" style="margin-left: 20px" valueFormat="YYYY-MM">
        <template #suffixIcon>
          <img style="width: 15px; height: 15px; display: flex" src="@/assets/images/table/timePickIcon.png" alt="" />
        </template>
      </a-month-picker>
      <div class="btns searchs" @click="search">{{ t('search') }}</div>
      <div class="btns reset" @click="reset">{{ t('reset') }}</div>
    </div>
    <!-- <div class="numsBox">
      <div class="title">月度汇总</div>
      <div class="listBox">
        <div class="items" v-for="item in 16" :key="item">
          <div class="conTop">
            <div class="time">22-08</div>
            <div class="subNums">$4200.98</div>
          </div>
          <div class="conBot">
            <div class="left">销售佣金</div>
            <div class="right">$2048.98</div>
          </div>
          <div class="conBot">
            <div class="left">坑位费</div>
            <div class="right">$2152.00</div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="centerView" style="padding-top: 0">
      <a-table v-if="!loadRef" :dataSource="list" :columns="columns" :pagination="false" :row-key="r => r.id" :scroll="{ x: 1400 }" size="small">
        <template #cycle="{ text }">{{ text.startTime }} / {{ text.endTime }}</template>
        <template #saleNumber="{ text }">
          {{ text.type == 1 ? text.saleNumber : '--' }}
        </template>
        <template #saleAmountUSD="{ text }">
          {{ text.type == 1 ? text.saleAmountUSD : '--' }}
        </template>
        <template #returnsNum="{ text }">
          {{ text.type == 1 ? text.returnsNum : '--' }}
        </template>
        <template #returnsAmountUSD="{ text }">
          {{ text.type == 1 ? text.returnsAmountUSD : '--' }}
        </template>
        <template #amountUSD="{ text }">
          {{ text.type == 1 ? text.amountUSD : '--' }}
        </template>

        <template #operating="{ text }">
          <div class="operaBtns">
            <div
              class="iconMes"
              :style="{
                opacity: text.type == 1 || text.type == 2 ? '1' : '0.5'
              }"
            >
              <a-tooltip>
                <template v-if="text.type == 1 || text.type == 2" #title>{{ t('particulars') }}</template>
                <template v-else #title>{{ text.remark }}</template>
                <img @click="toDetail(text)" style="width: 20px; height: 20px; display: flex" src="@/assets/images/table/fileIcon.png" alt="" />
              </a-tooltip>
            </div>
            <div
              class="iconMes"
              :style="{
                opacity: text.type == 1 || text.type == 2 ? '1' : '0.5'
              }"
            >
              <a-tooltip>
                <template #title v-if="text.type == 1 || text.type == 2">{{ t('download') }}</template>
                <img @click="download(text)" style="width: 20px; height: 20px; display: flex" src="@/assets/images/table/downloadIcon.png" alt="" />
              </a-tooltip>
            </div>
            <a-popconfirm :title="t('popconfirmMessage')" :ok-text="t('affirm')" :cancel-text="t('cancel')" @confirm="submitRow(text)" v-if="text.state == 10">
              <a-button type="primary" size="small" style="margin-left: 8px; width: 90px">
                {{ t('affirm') }}
              </a-button>
            </a-popconfirm>
            <a-button type="primary" size="small" style="margin-left: 8px; width: 90px" disabled v-else>
              {{ t('confirmed') }}
            </a-button>
          </div>
          <!-- <a-button
            type="link"
            size="small"
            style="margin-bottom: 0"
            @click="toDetail(text)"
          >
            明细
          </a-button> -->

          <!-- <a-button
            type="link"
            size="small"
            style="margin-bottom: 0"
            @click="download(text)"
          >
            下载
          </a-button> -->
        </template>
      </a-table>
      <div style="width: 100%" v-else>
        <a-space :loading="false" :active="true">
          <template v-for="item in 8" :key="item">
            <a-skeleton-button :active="true" style="width: 100%; margin-bottom: 8px; height: 70px"></a-skeleton-button>
          </template>
        </a-space>
      </div>
      <div class="pagination_cont">
        <a-pagination v-model:current="page.page" :total="page.total" @change="pageChange" />
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, watch } from 'vue'
  import { getStoreList } from '../../../api/live'
  import { statement_page, statement_excel, statement_confirm } from '../../../api/financial'
  import { useRouter } from 'vue-router'
  import { message } from 'ant-design-vue'
  import moment from 'moment'
  import { useI18n } from 'vue-i18n'

  export default {
    name: 'settlement',
    components: {},
    setup() {
      const loadRef = ref(true)
      const columns = ref()
      const { t, locale } = useI18n()
      const bId = ref(null)
      const storeList = ref([])
      const month = ref(null)
      const list = ref([])

      const router = useRouter()

      function getStoreListFn() {
        getStoreList().then(res => {
          if (res.code === 200) {
            storeList.value = res.data
          }
        })
      }

      getStoreListFn()

      // watch(
      //   () => bId.value,
      //   () => {
      //     console.log(1);
      //     statement_pageFn();
      //   }
      // );
      // watch(
      //   () => month.value,
      //   () => {
      //     console.log(1);
      //     statement_pageFn();
      //   }
      // );
      watch(locale, () => {
        columnFun()
      })
      const columnFun = () => {
        columns.value = [
          {
            title: t('tablement.month'),
            dataIndex: 'name',
            key: 'name',
            width: 80
          },
          {
            title: t('tablement.settlement'),
            width: 170,
            slots: { customRender: 'cycle' }
          },
          {
            title: t('tablement.mentType'),
            width: 70,
            key: 'typeVal',
            dataIndex: 'typeVal'
          },
          {
            title: t('tablement.shopName'),
            width: 100,
            key: 'typeVal',
            dataIndex: 'storesName'
          },
          {
            title: t('tablement.sellNums'),
            width: 70,
            key: 'saleNumber',
            slots: { customRender: 'saleNumber' }
          },
          {
            title: t('tablement.sellPrice'),
            width: 70,
            key: 'saleAmountUSD',
            slots: { customRender: 'saleAmountUSD' }
          },
          {
            title: t('tablement.returnNums'),
            width: 70,
            key: 'returnsNum',
            slots: { customRender: 'returnsNum' }
          },
          {
            title: t('tablement.returnPrice'),
            width: 70,
            key: 'returnsAmountUSD',
            slots: { customRender: 'returnsAmountUSD' }
          },
          {
            title: t('tablement.settlementPrice'),
            width: 70,
            key: 'amountUSD',
            dataIndex: 'amountUSD'
          },
          {
            title: t('tablement.operate'),
            fixed: 'right',
            align: 'center',
            width: 150,
            slots: { customRender: 'operating' }
          }
        ]
      }
      columnFun()
      const page = ref({
        page: 1,
        size: 10,
        total: 0
      })
      //查询
      const search = () => {
        page.value.page = 1
        statement_pageFn()
      }
      //重置
      const reset = () => {
        bId.value = null
        month.value = null
        statement_pageFn()
      }
      const statement_pageFn = () => {
        loadRef.value = true
        statement_page({
          ...page.value,
          storesIdCode: bId.value,
          name: month.value
        }).then(res => {
          console.log(res)
          setTimeout(() => {
            loadRef.value = false
          }, 1000)
          list.value = res.data.data
          page.value.total = res.data.total
        })
      }
      statement_pageFn()

      const toDetail = text => {
        console.log(text)
        if (text.type == 1 || text.type == 2) {
          router.push({
            name: 'financialDetail',
            query: {
              type: text.type,
              id: text.id
            }
          })
        }
        // 店铺场佣和店铺佣金
        if (text.type == 8 || text.type == 9) {
          router.push({
            name: 'financialDetail',
            query: {
              type: text.type,
              id: text.id
            }
          })
        }
      }

      const pageChange = val => {
        page.value.page = val
        statement_pageFn()
      }

      const download = text => {
        if (text.type == 1 || text.type == 2) {
          let companyName = localStorage.getItem('userName')
          let fileName

          if (text.type == 1) {
            fileName = companyName.split('@')[1] + '_commission_' + moment().format('YYYY-MM-DD') + '.xlsx'
          } else {
            fileName = companyName.split('@')[1] + '_spot_fee_' + moment().format('YYYY-MM-DD') + '.xlsx'
          }

          statement_excel(
            {
              id: text.id
            },
            {
              fileName: fileName
            }
          )
        }
      }

      const submitRow = row => {
        statement_confirm({
          id: row.id
        }).then(res => {
          if (res.code == 200) {
            message.success('确认成功～')
            statement_pageFn()
          }
        })
      }

      return {
        t,
        locale,
        submitRow,
        download,
        pageChange,
        toDetail,
        page,
        columns,
        list,
        storeList,
        month,
        bId,
        search,
        reset,
        loadRef
      }
    }
  }
</script>
<style>
  .ant-tooltip-inner {
    /* opacity: 0.8; */
    background: #7a7576;
    border-radius: 8px;
  }
  .ant-calendar-month-panel {
    border-radius: 8px;
  }
  .ant-calendar {
    border: 1px solid #ffc299;
    border-radius: 8px;
  }
  .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
    background: #ff6700;
  }
  .ant-calendar-header a:hover {
    color: #fff;
  }
  .ant-calendar-month-panel-month:hover {
    background: #ff6700;
    cursor: pointer;
  }
  .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month:hover {
    background: #ff6700;
  }
</style>
<style scoped lang="less">
  ::v-deep(.ant-space-align-center) {
    width: 100%;
  }
  ::v-deep(.ant-space-item) {
    width: 100%;
  }
  ::v-deep(.ant-space) {
    display: flex;
    flex-direction: column;
  }
  .iconMes {
    width: 30px;
    line-height: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .iconMes:hover {
    background: #fff5ec;
  }
  ::v-deep(.ant-btn-sm) {
    background: #ff6700;
    border-radius: 6px;
    width: 68px;
    height: 32px;
    line-height: 32px;
    border-color: #ff6700;
  }
  ::v-deep(.ant-btn-primary[disabled]) {
    background: #d2d2d2;
    border-color: #d2d2d2;
  }
  .operaBtns {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .numsBox {
    width: 100%;
    padding: 13px 0 0 32px;
    box-sizing: border-box;
    margin-bottom: 40px;
    .title {
      color: #131011;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 16px;
    }
    .listBox {
      width: 100%;
      // display: flex;
      // flex: 1;
      white-space: nowrap;
      overflow: hidden;
      overflow-x: scroll;
      .items {
        display: inline-block;
        width: 171px;
        height: 88px;
        margin-right: 16px;
        background: #fafafa;
        border: 1px solid #f7f7f7;
        border-radius: 8px;
        padding: 8px 12px;
        box-sizing: border-box;
        .conTop {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 7px;
          .time {
            font-size: 16px;
            line-height: 22px;
            color: #ff6700;
          }
          .subNums {
            font-size: 14px;
            line-height: 20px;
            color: #131011;
          }
        }
        .conBot {
          display: flex;
          margin-bottom: 2px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .left {
            color: #7a7576;
            font-size: 12px;
            line-height: 18px;
          }
          .right {
            font-size: 14px;
            line-height: 20px;
            color: #131011;
          }
        }
      }
    }
  }
  ::v-deep(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
    border-radius: 10px;
    height: 40px;
  }
  ::v-deep(.ant-input) {
    border-radius: 10px;
    height: 40px;
  }
  ::v-deep(.ant-select-single.ant-select-show-arrow .ant-select-selection-item) {
    line-height: 40px;
  }
  ::v-deep(.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder) {
    line-height: 40px;
  }
  .searchBox {
    display: flex;
    align-items: center;
  }
  .btns {
    margin-left: 20px;
    height: 40px;
    width: 80px;
    border-radius: 8px;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    cursor: pointer;
  }
  .searchs {
    color: #fff;
    background: #ff6700;
  }
  .reset {
    color: #101310;
    background: #d2d2d2;
  }
</style>
